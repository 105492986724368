import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import zh_CN from "antd/es/locale/zh_CN";
import moment from "moment";
import PropTypes from "prop-types";
import "moment/dist/locale/zh-cn";
export default function MyDatePicker({ value, onChange, style, ...extra }) {
  return (
    <ConfigProvider locale={zh_CN}>
      <DatePicker
        style={style}
        value={value ? moment(value * 1000) : null}
        onChange={moment => {
          return onChange(moment ? moment.startOf("day").unix() : moment);
        }}
        {...extra}
      />
    </ConfigProvider>
  );
}

MyDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

MyDatePicker.defaultProps = {
  value: undefined,
  onChange: () => {},
  style: {
    width: 200,
  },
};
