import { useLoading, useLoadingPage, useLoadings, useLoadingPages } from "./useLoading";
import usePersistFn from "./usePersistFn";
import useMount from "./useMount";
import useUnMount from "./useUnMount";
import useDebounce from "./useDebounce";
import useContentWrapper from "./useContentWrapper";

const customHooks = {
  usePersistFn,
  useLoading,
  useLoadingPage,
  useLoadings,
  useLoadingPages,
  useMount,
  useUnMount,
  useDebounce,
  useContentWrapper,
};

export default customHooks;
