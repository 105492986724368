/* eslint-disable camelcase */ // 允许使用_下划线命名形式
import loadingPage from "../components/loadingPage";
import cookie from "./cookie";
import initEnv from "./initEnv";
import c_fetch from "./c_fetch";
import { removeEmptyField } from "./utils";

const { getCookie } = cookie;
const { baseUrl, cookieName } = initEnv;

// 经实例化以后可直接调用 appState.uploadFile() | appState.fetch()
class AppState {
  static getObjectConstructorName(object) {
    return object && Object.getPrototypeOf(object).constructor.name;
  }

  static newFormData(formData) {
    if (typeof formData !== "object") throw Error("newFormData请传入object类型");
    const formD = new FormData();
    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        formD.append(key, formData[key]);
      }
    }
    return formD;
  }

  static searchToUrl(searchObj = {}) {
    return Object.keys(searchObj).length > 0
      ? `?${Object.keys(searchObj)
          .map(key => `${key}=${searchObj[key]}`)
          .join("&")}`
      : "";
  }

  static forInObj(object, callback) {
    if (typeof object !== "object") throw Error("forInObj请传入object类型");

    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key)) {
        const value = object[key];
        callback(key, value);
      }
    }
  }

  static initVal = {
    loginToken: getCookie(cookieName),
    baseUrl: baseUrl,
    body: null,
    headers: {},
  };

  isGetLoading = true;

  constructor() {}

  #initThisVal(initVal) {
    AppState.forInObj(initVal, (key, value) => {
      this[key] = value;
    });
  }

  // 私有属性代表appState 默认拦截处理
  #willRequest = () => {
    // 默认 GET 请求打开
    if (this.isGetLoading) {
      loadingPage.start();
    }
  };

  // 针对请求路径和配置做进一步处理啊
  #updateUrl({ url, search, initUrl }) {
    this.baseUrl += url;
    if (initUrl) {
      this.baseUrl = initUrl;
    }
    this.baseUrl += AppState.searchToUrl(removeEmptyField(search));
  }

  #updateBody({ body, formData }) {
    if (formData) {
      this.body = AppState.newFormData(removeEmptyField(formData));
    }

    if (body) {
      // eslint-disable-next-line no-extra-boolean-cast
      this.body = Boolean(typeof body === "object" && body.constructor !== FormData)
        ? JSON.stringify(removeEmptyField(body))
        : body;
    }
  }

  #updateHeader({ body, formData, headers }) {
    this.headers = new Headers();
    this.loginToken = getCookie(cookieName);
    // 出去formData以外默认Content-Type类型都是application/json
    if (!(AppState.getObjectConstructorName(body) === "FormData" || formData)) {
      this.headers.append("Content-Type", "application/json");
    }
    // 请求前拦截，用户登录情况下写入请求头token
    if (this.loginToken && this.loginToken !== "undefined") {
      this.headers.append("Authorization", `Bearer ${this.loginToken}`);
    }
    // 正常headrs遍历添加Headers头部
    if (headers) {
      AppState.forInObj(headers, (key, value) => {
        this.headers.append(key, value);
      });
    }
  }

  #willResponse = response => {
    loadingPage.end();
    // OSS 签名认证特殊处理
    const ossUrl = "http://cdn-oss-data-zxhj.oss-cn-zhangjiakou.aliyuncs.com/";
    // 批量导出 contentType为ms-excel类型
    const isXls =
      response.headers.get("content-type") &&
      response.headers.get("content-type").indexOf("application/vnd.ms-excel") > -1;
    if (response.url === ossUrl && response.status === 200) {
      return { code: 200 };
    }
    // 确认返回类型是 xls 表格系列
    if (isXls) {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const disposition = response.headers.get("Content-Disposition");
        let filename = disposition ? disposition.split("=")[1] : "";
        if (filename) {
          filename = decodeURIComponent(filename);
        }
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        return { code: 200, data: true };
      });
    }
    return response;
  };

  #getOSS() {
    return this.fetch("/upload/uploadPolicy", {
      method: "GET",
    });
  }

  // 新增文件上传通用服务
  uploadFile(info = {}) {
    const { file } = info;
    const { size, name } = file;
    const unOssFileMaxSize = 5 * 1024 * 1024; // 精确到字节 默认最大 5MB
    return new Promise(resolve => {
      if (size < unOssFileMaxSize) {
        const formData = new FormData();
        formData.append("file", info.file);
        this.fetch("/upload", {
          body: formData,
        }).then(data => {
          const { link } = data;
          resolve({ link });
        });
      } else {
        this.#getOSS().then(data => {
          const { accessid, cdnPath, dir, host, policy, signature } = data;
          const ossFormData = new FormData();
          ossFormData.append("key", `${dir}${name}`); // 存储在oss的文件路径
          ossFormData.append("policy", policy); // policy
          ossFormData.append("OSSAccessKeyId", accessid); // accessKeyId
          ossFormData.append("success_action_status", "200"); // 成功后返回的操作码
          ossFormData.append("signature", signature); // 签名
          ossFormData.append("file", file);
          this.fetch(host, {
            initUrl: host,
            method: "POST",
            body: ossFormData,
          }).then(() => {
            resolve({
              link: cdnPath + name,
            });
          });
        });
      }
    });
  }

  // 通用请求
  fetch(url, init = {}) {
    this.#initThisVal(AppState.initVal);
    const { body, formData, search, initUrl, method, headers } = init;
    this.#updateHeader({ body, formData, headers });
    this.#updateBody({ body, formData });
    this.#updateUrl({ search, url, initUrl });

    return c_fetch({
      input: this.baseUrl,
      init: {
        body: this.body,
        method: method || "POST",
        headers: this.headers,
      },
      willRequest: this.#willRequest,
      willResponse: this.#willResponse,
    });
  }

  requestIntercept(callback) {
    c_fetch.interceptors.request.use(callback);
  }

  responseIntercept(callback) {
    c_fetch.interceptors.response.use(callback);
  }
}

export default AppState;
