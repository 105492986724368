import React, { useMemo } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import AppState from "../utils/appState";

const leaveFileList = (fileList, single, status) => {
  // 单图片模式
  if (single) {
    if (fileList.length === 0) {
      return "";
    } else {
      return fileList[0].url;
    }
  } else {
    return fileList.map(file => file.url);
  }
};

const changeLeaveFileList = (fileList, single, status) => {
  // 更新模式
  if (status === "uploading") {
    return fileList.map(file => file);
  } else {
    // 删除模式
    if (single) {
      return "";
    } else {
      return fileList.map(file => file.url);
    }
  }
};

const toFileList = value => {
  let fileList = [];
  if (typeof value === "string") {
    if (value) {
      fileList.push({
        uid: value,
        url: value,
      });
    } else {
    }
  } else if (Array.isArray(value)) {
    if (typeof value[0] === "string") {
      fileList = value.map(v => ({
        uid: v,
        url: v,
      }));
    } else {
      fileList = value.map(v => ({
        ...v,
      }));
    }
  } else {
  }
  return fileList;
};

const ImageUpload = function ImageUpload({ value, onChange, uploadMax, single, style, uploadContent, ...extra }) {
  const appState = useMemo(() => {
    const instance = new AppState();
    instance.isGetLoading = false;
    return instance;
  }, []);

  value = value || "";
  uploadMax = uploadMax || 1;

  const fileList = toFileList(value);
  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    if (/\.(png|jpe?g|gif|svg)(\?.*)?$/.test(src)) {
      console.log("匹配到的是图片预览哦~");
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
      return;
    }
    window.location.href = src;
  };

  const beforeUpload = file => {
    return new Promise((resolve, reject) => {
      let fileSize = parseFloat(parseInt(file["size"]) / 1024).toFixed(2);
      console.log("转换前大小:", fileSize);
      if (fileSize > 512) {
        let rate = 0.6;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let img = new Image();
        reader.onload = function (e) {
          img.src = e.target.result;
        };
        img.onload = function () {
          let canvas = document.createElement("canvas");
          let context = canvas.getContext("2d");
          // 文件大小KB
          const fileSizeKB = parseFloat(parseInt(file["size"]) / 1024 / 1024).toFixed(2);
          // 配置rate和maxSize的关系
          if (fileSizeKB > 3) {
            rate = 0.3;
          } else if (fileSizeKB > 1) {
            rate = 0.45;
          } else {
            rate = 0.6;
          }
          // if (fileSizeKB * rate > 3027) {
          //   rate = Math.floor((3027 / fileSizeKB) * 10) / 30;
          // }
          console.log(rate);
          // 缩放比例，默认0.5
          let targetW = (canvas.width = this.width * rate);
          let targetH = (canvas.height = this.height * rate);
          context.clearRect(0, 0, targetW, targetH);
          context.drawImage(img, 0, 0, targetW, targetH);
          canvas.toBlob(blob => {
            const newImage = new File([blob], file.name, {
              type: file.type,
            });
            resolve(newImage);
          });
        };
      } else {
        resolve(file);
      }
    });
  };

  const customRequest = async info => {
    console.log("转换后大小:", parseFloat(parseInt(info.file["size"]) / 1024).toFixed(2));
    const data = await appState.uploadFile(info);
    let newFileList = [
      ...fileList,
      {
        uid: data.link,
        url: data.link,
      },
    ];
    // eslint-disable-next-line no-prototype-builtins
    newFileList = newFileList.filter(file => !file.hasOwnProperty("status"));
    console.log(newFileList, "newFileList");
    onChange(leaveFileList(newFileList, single));
  };

  const uploadButton = useMemo(() => uploadContent, []);

  return (
    <div
      style={style}
      className="imageUpload"
      children={
        <Upload
          customRequest={customRequest}
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onChange={({ fileList: newFileList, file, event }) => {
            onChange(changeLeaveFileList(newFileList, single, file.status));
          }}
          onPreview={onPreview}
          {...extra}
        >
          {fileList.length >= uploadMax ? null : uploadButton}
        </Upload>
      }
    />
  );
};
ImageUpload.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  uploadMax: PropTypes.number,
  single: PropTypes.bool,
  style: PropTypes.object,
};
ImageUpload.defaultProps = {
  value: "",
  uploadMax: 1,
  onChange: () => {},
  single: true,
  // style: {
  //   minHeight: 112,
  // },
  uploadContent: (
    <div>
      <PlusOutlined />
      <div style={{ marginLeft: 8 }}>Upload</div>
    </div>
  ),
};

export default ImageUpload;
