import React, { useState, useMemo } from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { pinyin } from "pinyin-pro";
import AppState from "../utils/appState";

const { Dragger } = Upload;

const FujianIcon = () => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg width="1em" height="1em" fill="currentColor" viewBox="64 64 896 896" color="rgba(0, 0, 0, 0.45)">
        <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z" />
      </svg>
    </div>
  );
};
const TrashIcon = ({ onClick }) => (
  <div style={{ display: "flex", alignItems: "center" }} onClick={onClick}>
    <svg width="1em" height="1em" fill="currentColor" viewBox="64 64 896 896" color="rgba(0, 0, 0, 0.45)">
      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
    </svg>
  </div>
);

// 获取文件路径的文件名
const getName = v => {
  const vSplit = v && v.split("/");
  return vSplit[vSplit.length - 1];
};

const FileItem = ({ fileUrl, onDelete }) => {
  return (
    <div className="file-item">
      <FujianIcon />
      <div style={{ textAlign: "left", flex: 1 }}>
        <a href={fileUrl}>
          <div className="danHangEllipsis" style={{ width: 222 }}>
            {getName(fileUrl)}
          </div>
        </a>
      </div>
      <TrashIcon onClick={onDelete} />
    </div>
  );
};

const MultipleUpload = ({ value = [], onChange = () => {}, onDeleteClick = () => {}, maxSize = 10 }) => {
  const appState = useMemo(() => new AppState(), []);
  const beforeUpload = (file, fileList) => {
    return new Promise((resolve, reject) => {
      if (file.size / 1024 / 1024 < maxSize) {
        resolve();
      } else {
        message.error("只允许上传10M以内的文件");
        reject();
      }
    });
  };
  const props = {
    name: "file",
    showUploadList: false,
    multiple: true,
    beforeUpload: beforeUpload,
    customRequest: info => {
      const {
        file: { name },
      } = info;
      const fileName = name;
      appState
        .fetch("/upload/uploadPolicy", {
          method: "GET",
        })
        .then(data => {
          const { accessid, cdnPath, dir, host, policy, signature } = data;
          const ossFormData = new FormData();
          ossFormData.append("key", `${dir}${fileName}`); //存储在oss的文件路径
          ossFormData.append("policy", policy); //policy
          ossFormData.append("OSSAccessKeyId", accessid); //accessKeyId
          ossFormData.append("success_action_status", "200"); //成功后返回的操作码
          ossFormData.append("signature", signature); //签名
          ossFormData.append("file", info.file);
          appState
            .fetch(host, {
              initUrl: host,
              method: "POST",
              body: ossFormData,
            })
            .then(() => {
              const newUrl = cdnPath + fileName;
              info.onSuccess(newUrl);
            });
        });
    },
    onChange(info) {
      const { file } = info;
      file.response && onChange([...value, file.response]);
      // setFileList(
      //   fileList.map(i => ({
      //     uid: i.uid,
      //     name: i.name,
      //     status: i.status,
      //     // 这里对应到 onSuccess 的回调参数
      //     url: i.response,
      //     thumbUrl: i.response,
      //     lastModifiedTime: i.lastModifiedDate?.toUTCString(),
      //   })),
      // );
    },
  };
  return (
    <div style={{ paddingBottom: 20 }}>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">单击或拖动文件到此区域以上载</p>
        <p className="ant-upload-hint">支持单个或批量上传</p>
      </Dragger>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {value.map((fileUrl, index) => (
          <FileItem
            fileUrl={fileUrl}
            onDelete={() => {
              onDeleteClick(fileUrl);
              value.splice(index, 1);
              onChange([...value]);
            }}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleUpload;
