import React from "react";
import "braft-editor/dist/index.css";
import BraftEditor from "braft-editor";
import AppState from "../utils/newAppState";
import MultipleUpload from "../components/MultipleUpload";

const appState = new AppState();

const myUploadFn = async param => {
  const { link, name, path } = await appState.uploadFile(param);
  param.success({
    url: link,
    meta: {
      id: name,
      title: name,
      alt: name,
    },
  });
};

// 注意富文本用于弹窗中,最好配合destroyOnClose弹窗销毁属性，
export default class RichTextBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 创建一个空的editorState作为初始值
      editorState: BraftEditor.createEditorState(props.value || null),
      editorValue: props.value,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.value !== state.editorValue) {
      return {
        editorValue: nextProps.value,
        editorState: BraftEditor.createEditorState(nextProps.value),
      };
    }
    return null;
  }

  render() {
    const { onChange = () => {}, value, onFileChange, fileList, showFileList, ...extra } = this.props;
    const { editorState } = this.state;
    const extendControls = [
      showFileList && {
        key: "custom-modal",
        type: "modal",
        text: "添加附件",
        modal: {
          id: "my-moda-1",
          title: "",
          children: (
            <div style={{ width: 1000, padding: "0 10px" }}>
              <MultipleUpload value={fileList} onChange={onFileChange} />
            </div>
          ),
        },
      },
    ].filter(Boolean);

    return (
      <div>
        <div className="editor-wrapper">
          <BraftEditor
            {...extra}
            value={editorState}
            onChange={_editorState => {
              const htmlEdit = _editorState.toHTML().replace("<p></p>", "");
              this.setState({
                editorState: _editorState,
                editorValue: htmlEdit,
              });
              onChange(htmlEdit);
            }}
            media={{ uploadFn: myUploadFn }}
            // controls={controls}
            extendControls={extendControls}
          />
        </div>
      </div>
    );
  }
}
